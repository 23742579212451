import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueResource, {Http} from 'vue-resource'
import './lib/leaflet.css'
import './lib/side-by-side/layout.css'
import './lib/side-by-side/range.css'
import './fonts/YouSheBiaoTiHei.css'
Vue.use(vueResource)

import globalJS from './lib/global';
Vue.prototype.globalJS = globalJS;

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '共生地球App'
  }
  if(to.query.share == 1){
    let data = new FormData()
    data.append('link',to.fullPath)
    Http.post("/earthApi/shareRead", data)
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
