import { render, staticRenderFns } from "./PlaneRecognition.vue?vue&type=template&id=84b15a26&scoped=true&"
import script from "./PlaneRecognition.vue?vue&type=script&lang=js&"
export * from "./PlaneRecognition.vue?vue&type=script&lang=js&"
import style0 from "./PlaneRecognition.vue?vue&type=style&index=0&id=84b15a26&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84b15a26",
  null
  
)

export default component.exports