<template>
    <div id="compare" :style="style">
        <l-map ref="map" style="z-index: 0" :options="option" :maxBounds="maxBounds">
        </l-map>
        <div v-if="infoShow" class="info" :style="share == 1 ? 'top:0':'top:80px'">
            拍摄时间： {{topLayer.time}} &nbsp;&nbsp;&nbsp;拍摄卫星： {{topLayer.satellite}}&ensp;&ensp;
        </div>
        <div v-if="infoShow" class="info" style="bottom: 0">
            拍摄时间： {{bottomLayer.time}} &nbsp;&nbsp;&nbsp;拍摄卫星： {{bottomLayer.satellite}}&ensp;&ensp;
        </div>
        <div v-if="titleShow" id="mainTitle">
            <span>{{$route.query.title ? $route.query.title : '卷帘对比'}}</span>
        </div>
        <div v-if="share != 1 && backShow" class="backButton" @click="$router.go(-1)">
            <div class="iconfontFamily" style="color: white">&#xe637;</div>
        </div>
        <TopShareBar v-if="share ==1" />
    </div>
</template>

<script>

import L from "leaflet";
import {LMap} from 'vue2-leaflet';
import '@/lib/side-by-side/leaflet-side-by-side'
import TopShareBar from '@/components/TopShareBar';


export default {
    name: "Compare",
    components: {
        TopShareBar,
        LMap,
    },
    data() {
        return {
            zoom: 14,
            center: L.latLng(44.0, 125.4),
            maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
            option: {zoomControl: false, attributionControl: false, renderer: L.canvas(), maxZoom: 18, minZoom: 5},
            areaGeo:'{"coordinates":[[[125.31955561825227,44.0186853140785],[125.43682190653972,44.0186853140785],[125.43682190653972,43.928546500305295],[125.31955561825227,43.928546500305295],[125.31955561825227,44.0186853140785]]],"type":"Polygon"}',
            topLayer:{
                cite:'202012100204000213229',
                time: '2021.9.12',
                satellite: '高分03星'
            },
            bottomLayer:{
                cite:'202101182109000276486',
                time: '2021.11.12',
                satellite: '高分03星'
            },
            share: 0,

            //展示方式：全屏（默认）,文章内部
            compareFullScreen:true,
            titleShow:true,
            infoShow: true,
            backShow: true,

        }
    },
    methods: {
        addLayer(){
            let topLayer = L.tileLayer(this.globalJS.charmingglobeLayerUrl + this.topLayer.cite + '/{z}/{x}/{y}?token=Bearer%20a84a40c81f784490a4c5689187054abf',{tms:true}).addTo(this.$refs.map.mapObject);
            let bottomLayer = L.tileLayer(this.globalJS.charmingglobeLayerUrl + this.bottomLayer.cite + '/{z}/{x}/{y}?token=Bearer%20a84a40c81f784490a4c5689187054abf',{tms:true}).addTo(this.$refs.map.mapObject)
            L.control.sideBySide(topLayer, bottomLayer).addTo(this.$refs.map.mapObject);
            if(document.documentElement.clientHeight >= document.documentElement.clientWidth){
                document.getElementsByClassName("leaflet-sbs-range")[0].style.transform = "rotate(90deg) translateY(50vh)"
            } else {
                document.getElementsByClassName("leaflet-sbs-range")[0].style.transform = "rotate(90deg) translateY(50vw)"
            }
            this.$refs.map.mapObject.setMaxBounds(L.geoJSON(JSON.parse(this.areaGeo)).getBounds())
            this.$refs.map.mapObject.fitBounds(L.geoJSON(JSON.parse(this.areaGeo)).getBounds())
            this.$refs.map.mapObject.setMinZoom(this.$refs.map.mapObject.getZoom())
        },
    },
    mounted() {
        this.topLayer = {
            cite: this.$route.query.topLayerCite,
            time: this.$route.query.topLayerTime,
            satellite: this.$route.query.topLayerSatellite
        }
        this.bottomLayer = {
            cite: this.$route.query.bottomLayerCite,
            time: this.$route.query.bottomLayerTime,
            satellite: this.$route.query.bottomLayerSatellite
        }
        this.areaGeo = decodeURI(this.$route.query.areaGeo)
        this.share = this.$route.query.share
        setTimeout(()=>{this.addLayer()}, 100)
        // this.addLayer()

        //展示方式
        if(this.$route.query.compareFullScreen){
            this.compareFullScreen = false;
        }
        if(this.$route.query.titleShow){
            this.titleShow = false;
        }

        if(this.$route.query.infoShow){
            this.infoShow = false;
        }
        if(this.$route.query.backShow == "false"){
            this.backShow = false;
        }
    },
    computed:{
        style() {
            if(this.compareFullScreen){
                if(document.documentElement.clientHeight >= document.documentElement.clientWidth){
                    return "width: 100vh; height: 100%;margin-left:-" + parseInt((document.documentElement.clientHeight - document.documentElement.clientWidth)/2) + "px"
                } else {
                    return "width: 100%; height: 100vw;margin-top:-" + parseInt((document.documentElement.clientWidth - document.documentElement.clientHeight)/2) + "px"
                }
            }
            else{
                if(document.documentElement.clientHeight >= document.documentElement.clientWidth){
                    return "width: 100vh; height: 100%;"
                } else {
                    return "width: 100%; height: 100vw;"
                }
            }

        }
    }
}
</script>
<style scoped lang="scss">

#compare {
    font-family: 微软雅黑;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
}

#mainTitle{
    img{
        position: absolute;
        left: 24px;
        top:54px;
        z-index: 10;
    }
    span{
        position: absolute;
        left: 50px;
        top:52px;
        z-index: 10;
    }
}

.backButton{
    z-index: 10;
    height:48px;
    width: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 12px;
    position: fixed;
    left: 10px;
    top:40px;
}

.info{
    position: absolute;
    left: calc((100vh - 100vw) / 2 - 1px);
    width: 100vw;
    height: 30px;
    background: rgba(0,0,0,0.25);
    color: white;
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    text-align: right;
    z-index: 9998;
    pointer-events: none;
}




</style>
