<template>
    <div id="viewer">
        <l-map ref="map" style="z-index: 0" :maxBounds="maxBounds" :options="option" @load="wait()">
            <l-tile-layer ref="mapLayer" :url="this.globalJS.charmingglobeLayerUrl + list[currentIndex].cite + '/{z}/{x}/{y}?token=Bearer%20a84a40c81f784490a4c5689187054abf'" :tms="true" />
        </l-map>
        <div v-if="locked" class="locked">
            <img height="30" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACjklEQVRoQ+2ZTYhOURjHf/+NImo2ioWSJVIyLHxbWKFICKXYKIqFhdgYCxuxsKBsWMhHUaawsTEmLDALMlspYauQsvnr1Bldd7zv/Zz3zpt76y7e9zznOf/feZ5zz5fo80d9rp8WIB1B27OBZfFdHctfAG/DK+l7nVGvNQK2jwMngAUdRH4ELkq6VBdEbQC2nwLrcwoblbQhp21Xs1oAbL8DlhQUNC5pacE6k8wrA9jeDtxPef4G3ASex//XAPuBOSm7HZKGq0BUArA9ADxL9f4D4LCkL0lhtucDV4Ftif/HgbWSvpaFqApwELiWaHxE0qZuYmw/ATYmbA5Jut4UwGXgSKLxrZIeZQBsAR4mbK5IOtoUwEtgZWz8FzAg6WcGwEwgpMyMaPdK0qqmAFwkfSZs02kkqXQql64YxNhuAUJHtBEoO4D6JoVshxk0zLiLUrBDyUEMjOTsjDAPJOeCpJ/g4j0wLCnM6F2fXIPY9mtgRZazmsvHJA1m+cwEsL0XuJXlaIrK90m63c13HoAzQDrEU6R3ktshSWdbgP8xAmE99AZYCMyrkG+NpFAYLxck/YjLjfC53BXfuQVheg7QsUHb64DR6Q4wKGmsk0jb94CdBSB6G4GsRZnt9GYmi2XaAZwGzmWpTpT3FgA4Kel836ZQFL5b0t00hO1wGnesQO8H055HYELfHeCDpFPxUxp+7ykovlGA0PifI5Z/HKXkZWksAi1ADFEbgXB0UnY/0PdjIO9A7WbXaAq1AE1OZHX0fuMTWR0QtYyBzcDjOtSU8HFA0o2qpxKz4jXS8hICqlT5DIQN0l9XVWmHmedCcTE2sa9dXEVRgbqfgHBzEy7Iuz65ALKcNFneAjTZ+6Ht37efTEB+6CE9AAAAAElFTkSuQmCC"/>
            <br/>您未参与该期众筹，暂无法查看
        </div>
        <div class="info">
            拍摄时间： {{list[currentIndex].endTime.substring(0,10).replaceAll('-','.')}} <br/>
            拍摄卫星： {{list[currentIndex].satellite}}
        </div>
        <div class="timeline">
            <div class="line" v-if="lightLineWidth != ''">
                <div class="subline" style="width: 100%"> </div>
                <div class="subline" style="background: #cac4f8" :style="lightLineWidth"> </div>
                <div class="subline" style="background: #675cb5" :style="darkLineWidth"> </div>
            </div>
            <template v-for="(item,index) in list">
                <div :key="item.crowdId" class="parts" @click="share != 1 && item.cite != '' ? currentIndex = index : {}">
                    <img v-if="item.assistStatus == 0" style="position: absolute; top:-14px; transform: translateX(-6px)" height="12" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACjklEQVRoQ+2ZTYhOURjHf/+NImo2ioWSJVIyLHxbWKFICKXYKIqFhdgYCxuxsKBsWMhHUaawsTEmLDALMlspYauQsvnr1Bldd7zv/Zz3zpt76y7e9zznOf/feZ5zz5fo80d9rp8WIB1B27OBZfFdHctfAG/DK+l7nVGvNQK2jwMngAUdRH4ELkq6VBdEbQC2nwLrcwoblbQhp21Xs1oAbL8DlhQUNC5pacE6k8wrA9jeDtxPef4G3ASex//XAPuBOSm7HZKGq0BUArA9ADxL9f4D4LCkL0lhtucDV4Ftif/HgbWSvpaFqApwELiWaHxE0qZuYmw/ATYmbA5Jut4UwGXgSKLxrZIeZQBsAR4mbK5IOtoUwEtgZWz8FzAg6WcGwEwgpMyMaPdK0qqmAFwkfSZs02kkqXQql64YxNhuAUJHtBEoO4D6JoVshxk0zLiLUrBDyUEMjOTsjDAPJOeCpJ/g4j0wLCnM6F2fXIPY9mtgRZazmsvHJA1m+cwEsL0XuJXlaIrK90m63c13HoAzQDrEU6R3ktshSWdbgP8xAmE99AZYCMyrkG+NpFAYLxck/YjLjfC53BXfuQVheg7QsUHb64DR6Q4wKGmsk0jb94CdBSB6G4GsRZnt9GYmi2XaAZwGzmWpTpT3FgA4Kel836ZQFL5b0t00hO1wGnesQO8H055HYELfHeCDpFPxUxp+7ykovlGA0PifI5Z/HKXkZWksAi1ADFEbgXB0UnY/0PdjIO9A7WbXaAq1AE1OZHX0fuMTWR0QtYyBzcDjOtSU8HFA0o2qpxKz4jXS8hICqlT5DIQN0l9XVWmHmedCcTE2sa9dXEVRgbqfgHBzEy7Iuz65ALKcNFneAjTZ+6Ht37efTEB+6CE9AAAAAElFTkSuQmCC"/>
                    <div class="point" :style="pointStyle(index, item)"></div>
                    {{item.title}}<br/>
                    {{dateFormatter(item.endTime)}}
                </div>
            </template>
        </div>
        <div v-if="share!=1" class="compareEntry" @click="compareSelectShow = true">
            卷帘对比
        </div>
        <div v-if="compareSelectShow" class="compareSelect">
            <div id="top"></div>
            <template v-for="item in list">
                <div :key="item.crowdId" v-if="item.cite != ''" class="item">
                    <div class="selector" @click="select(item)" :style="selected.indexOf(item) != -1 ? 'border: 2px solid #6a6ec3;':''">
                        <div v-if="selected.indexOf(item) != -1" class="selectedPoint"></div>
                    </div>
                    <div class="thumb">
                        <img class="thumbPicture" :src="item.thumbLink" />
                        <div v-if="item.assistStatus == 0" class="thumbLock">
                            <img style="margin: 33px" height="20" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACjklEQVRoQ+2ZTYhOURjHf/+NImo2ioWSJVIyLHxbWKFICKXYKIqFhdgYCxuxsKBsWMhHUaawsTEmLDALMlspYauQsvnr1Bldd7zv/Zz3zpt76y7e9zznOf/feZ5zz5fo80d9rp8WIB1B27OBZfFdHctfAG/DK+l7nVGvNQK2jwMngAUdRH4ELkq6VBdEbQC2nwLrcwoblbQhp21Xs1oAbL8DlhQUNC5pacE6k8wrA9jeDtxPef4G3ASex//XAPuBOSm7HZKGq0BUArA9ADxL9f4D4LCkL0lhtucDV4Ftif/HgbWSvpaFqApwELiWaHxE0qZuYmw/ATYmbA5Jut4UwGXgSKLxrZIeZQBsAR4mbK5IOtoUwEtgZWz8FzAg6WcGwEwgpMyMaPdK0qqmAFwkfSZs02kkqXQql64YxNhuAUJHtBEoO4D6JoVshxk0zLiLUrBDyUEMjOTsjDAPJOeCpJ/g4j0wLCnM6F2fXIPY9mtgRZazmsvHJA1m+cwEsL0XuJXlaIrK90m63c13HoAzQDrEU6R3ktshSWdbgP8xAmE99AZYCMyrkG+NpFAYLxck/YjLjfC53BXfuQVheg7QsUHb64DR6Q4wKGmsk0jb94CdBSB6G4GsRZnt9GYmi2XaAZwGzmWpTpT3FgA4Kel836ZQFL5b0t00hO1wGnesQO8H055HYELfHeCDpFPxUxp+7ykovlGA0PifI5Z/HKXkZWksAi1ADFEbgXB0UnY/0PdjIO9A7WbXaAq1AE1OZHX0fuMTWR0QtYyBzcDjOtSU8HFA0o2qpxKz4jXS8hICqlT5DIQN0l9XVWmHmedCcTE2sa9dXEVRgbqfgHBzEy7Iuz65ALKcNFneAjTZ+6Ht37efTEB+6CE9AAAAAElFTkSuQmCC"/>
                        </div>
                    </div>
                    <div v-if="item != lastValidItem" class="bottomLine"></div>
                    <div class="title">
                        {{item.title}}
                        <br/>
                        <span style="color: rgba(204,204,204,1); font-size: 12px">{{dateFormatter(item.endTime)}}</span>
                        <br/>
                        <div v-if="item.assistStatus == 0" style="margin-top: 4px">
                            <img height="16px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACC0lEQVRYR82XsYsTQRTGvxdC0mh9NoLgdcJZaKVVqnnRzlZEqxP0P9DCs9D/QMGrFLG107ypUmmlhQd2Cgc2Xq3NhpBPRm6P3b3N7cwmXJImkMz73m9m3sz7RrDij6w4P5IAxuPxuclkcovkbQAbIrIRJkDyAMCBiLzr9XrvB4PB79iJRQF476/MZrNtEbkLoN8gnpF80+l0dp1zX5tAGgFGo9ErEdluEqr7n+TucDi8f1LsiQBm9g3AVpvkhZg9Vb08T2MugJlxwcSlcFWtzVX7o5l9BDBcJgCAkareqGoeA0jZc5KDICgi4xjYupooAYRqJ/klRkxEdpxzT8NY7/0TkjuRcVeLp6MEkDL7kKzb7V4K39Pp9HtM8sM7o3QyjgC89+dJ/gDQixVrOW4iIpvOuV//ty8XMbNHAJ61FE0Ne6yqz6sAnwBcS1FKLcKC9mdVvV4FCMt/MQUgP9st7oyfqrpZAvDe/yF55jQAROSvc+7segGY2Wq3wMySi3CBGjhehN77ByRfnFINPHTOvSzVQHA7WZbtRxiOI8aWK5D1+/0LuWta6CpuA1BtSK2bUViGNgAiMr8ZBdGUhpR6Eza240JfWIYVq9ZzrTVbT0tWWIllWLNaK5bnWG9bnlMWHib3IgzLhOTrpT1MipV06JruALhZ9zQD8EFE3uZuJ+ZWbdyCGJFFxqwc4B8RXBQw1H++qwAAAABJRU5ErkJggg==" />
                            <span style="display:inline-block;color: rgba(204,204,204,1); font-size: 10px;">&nbsp;您未参与该期众筹，无法查看该图像</span>
                        </div>
                    </div>
                </div>
            </template>
            <div id="bottom">
                <img height="18px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACC0lEQVRYR82XsYsTQRTGvxdC0mh9NoLgdcJZaKVVqnnRzlZEqxP0P9DCs9D/QMGrFLG107ypUmmlhQd2Cgc2Xq3NhpBPRm6P3b3N7cwmXJImkMz73m9m3sz7RrDij6w4P5IAxuPxuclkcovkbQAbIrIRJkDyAMCBiLzr9XrvB4PB79iJRQF476/MZrNtEbkLoN8gnpF80+l0dp1zX5tAGgFGo9ErEdluEqr7n+TucDi8f1LsiQBm9g3AVpvkhZg9Vb08T2MugJlxwcSlcFWtzVX7o5l9BDBcJgCAkareqGoeA0jZc5KDICgi4xjYupooAYRqJ/klRkxEdpxzT8NY7/0TkjuRcVeLp6MEkDL7kKzb7V4K39Pp9HtM8sM7o3QyjgC89+dJ/gDQixVrOW4iIpvOuV//ty8XMbNHAJ61FE0Ne6yqz6sAnwBcS1FKLcKC9mdVvV4FCMt/MQUgP9st7oyfqrpZAvDe/yF55jQAROSvc+7segGY2Wq3wMySi3CBGjhehN77ByRfnFINPHTOvSzVQHA7WZbtRxiOI8aWK5D1+/0LuWta6CpuA1BtSK2bUViGNgAiMr8ZBdGUhpR6Eza240JfWIYVq9ZzrTVbT0tWWIllWLNaK5bnWG9bnlMWHib3IgzLhOTrpT1MipV06JruALhZ9zQD8EFE3uZuJ+ZWbdyCGJFFxqwc4B8RXBQw1H++qwAAAABJRU5ErkJggg==" />
                <span style="display:inline-block; transform: translateY(-4px)">&nbsp;卷帘展示仅支持2张图比较</span>
            </div>
            <div id="button" @click="goCompare()" :style="selected.length == 2 ? 'background: #675cb5;':''">
                确定
            </div>
            <div id="info">
                更多图像敬请期待
            </div>
        </div>
        <div id="mainTitle">
<!--            <img v-if="compareSelectShow" @click="compareSelectShow = false" height="14" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABF0lEQVRIS7XWIY4CQRCF4Z/zLIZwgj3ASggCgUAgECtWIEgAgUAgEAgEAoEgWA6wB1ixAk6CQZISJJOhu7q7pqcP8L6p7qlKNch3ZsAXcAcWwK8rupHJE2xeyBLssy6wjInzD7TqAF2YOHK1l9ygD+sCZ99TWd/QhMlHWEAzZgErYalgZSwFzILFgtmwGDArFgKzYyHwCnyUGlht6pi5rPXhH9AuhfSAU0ywZdL4rrQPHK1oaNL40AFwsKAhUDJ96BDYp6IxoIaOgF0KGgtq6BjYxqIpoIZ+A5sYNBXU0B9gHUItoIZOgJWGWkENnQJLS+OHbkdDZWWU3fTtVKnwFebq0xvQrAt0VVrrIlystAM8APljnav+E4G5Oh3qOqliAAAAAElFTkSuQmCC" />-->
<!--            <img v-if="!compareSelectShow && share!=1" @click="exit()" height="14" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABHklEQVRIS7XWK05EQRCF4fOvBwxhBSxgJASBQCAQiBEIBAkzAoFAIBAIBAJBsLOAWQACASvB4Jp0Agm5uV3V1dO3F3C+flWlUKeVUrqSNJP0JWkJrMei6eH9Yot/WWtgbxJwBMvOO7DTHSxg2ZkBq66ggR0Ar6WnanrDVixvIgxugoXBTbEQ2AOrBnthVWBPzAV7YyY4BeaBH5K2BgVsFnVNXy7WYUrpTdLuIOQQeKkJDnca40qPgOdW1Ow0BnoMPLWgbmsz0BPgMYq6YA400FPgIYJWgQ56BtzXotWgg86Buxo0BDroOXDroWHQQS+AGwttAh30ErgOF753NQ66AJZdh6i/sELJfALbk4CFk043CA9Oui/pW1L+saOj/g9hx44d+08TfwAAAABJRU5ErkJggg==" />-->
            <span :style="compareSelectShow ? '':'color:white'">{{compareSelectShow ? '选择对比图像': list[0].mainTitle + '时间轴对比'}}</span>
        </div>

        <div v-if="share!=1" class="backButton" @click="compareSelectShow ? compareSelectShow = false : exit()">
            <div class="iconfontFamily" :style="compareSelectShow ? 'color: black':'color:white'">&#xe637;</div>
        </div>

        <ShareButton v-if="share == 1" />
    </div>
</template>

<script>
import L from "leaflet";
import {LMap, LTileLayer} from "vue2-leaflet";
import ShareButton from "@/components/ShareButton";

export default {
    name: "Viewer",
    components: {
        ShareButton,
        LMap,
        LTileLayer
    },
    data(){
        return{
            list:[
                {
                    mainTitle: '',
                    assistStatus:1,
                    cite:"202012100204000213229",
                    shootGeo: '{"coordinates":[[[125.31955561825227,44.0186853140785],[125.43682190653972,44.0186853140785],[125.43682190653972,43.928546500305295],[125.31955561825227,43.928546500305295],[125.31955561825227,44.0186853140785]]],"type":"Polygon"}',
                    title: '第0期',
                    endTime: "2021-11-08 00:00:00",
                    thumbLink: "https://market.obs.myhuaweicloud.com:443/daxingjichang.png",
                    crowdId: "20211025113100011832",
                }
            ],
            maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
            option: {zoomControl: false, attributionControl: false, renderer: L.canvas(), maxZoom: 18, minZoom: 5},
            currentIndex: 0,
            lightLineWidth: '',
            darkLineWidth: '',
            locked: false,
            compareSelectShow: false,
            selected:[],
            infoId: 202110251131000111804,
            token: '',
            share: 0
        }
    },
    methods: {
        wait(){
            if (this.list[0].mainTitle == ''){
                setTimeout(this.wait,100);
            } else {
                this.maxBounds = L.geoJSON(JSON.parse(this.list[0].shootGeo)).getBounds()
                this.$refs.map.mapObject.fitBounds(this.maxBounds)
                this.currentIndex = this.$route.query.index
                setTimeout(()=>{
                    this.lightLineWidth = 'width:' + (document.getElementsByClassName("point")[this.validCount-1].getBoundingClientRect().left + 5) +'px'
                    this.darkLineWidth = 'width:' + (document.getElementsByClassName("point")[this.currentIndex].getBoundingClientRect().left + 5) +'px'
                }, 1)
            }
        },
        load() {
            this.$http.get('/earthApi/crowd-funding/getCrowdCiteList?infoId=' + this.infoId + '&share=' + this.share, {headers:{'Authorization': 'Bearer ' + this.token}}).then(response => {
                this.list = response.body.data
                this.wait()
            })
        },
        dateFormatter(val){
            let date = new Date(val)
            return date.getFullYear() + '.' + (date.getMonth()+1)
        },
        pointStyle(index, item){
            if (this.currentIndex >= index) {
                return 'background: linear-gradient(180deg,#675cb5, #8a7ee0)'
            } else if (item.cite != ''){
                return 'background: rgba(202,196,248,1)'
            }
        },
        select(val){
            if(val.assistStatus != 0){
                if(this.selected.indexOf(val) == -1 && this.selected.length < 2){
                    this.selected.push(val)
                } else if(this.selected.indexOf(val) != -1) {
                    this.selected.splice(this.selected.indexOf(val) , 1)
                }
            }
        },
        goCompare() {
            if(this.selected.length == 2){
                this.$router.push(
                    {
                        path: '/CrowdShoot/Compare',
                        query: {
                            topLayerCite: this.selected[0].cite,
                            topLayerTime: this.dateFormatter(this.selected[0].endTime).substring(0,7),
                            topLayerSatellite: this.selected[0].satellite,
                            bottomLayerCite: this.selected[1].cite,
                            bottomLayerTime: this.dateFormatter(this.selected[1].endTime).substring(0,7),
                            bottomLayerSatellite: this.selected[1].satellite,
                            areaGeo: this.selected[0].shootGeo
                        }
                    })
            }
        },
        exit() {
            // eslint-disable-next-line no-undef
             Exit.postMessage('')
        }
    },
    watch: {
        currentIndex(val){
            if(this.list[val].assistStatus == 0){
                this.locked = true
            } else {
                this.locked = false
            }
            this.darkLineWidth = 'width:' + (document.getElementsByClassName("point")[this.currentIndex].getBoundingClientRect().left + 5) +'px'
        },
    },
    computed: {
        lastValidItem() {
            let tmp
            this.list.forEach((i)=>{
                if(i.cite != ''){
                    tmp = i
                }
            })
            return tmp
        },
        validCount() {
            let count = 0
            this.list.forEach((item)=>{
                if(item.cite != ''){
                    count++
                }
            })
            return count
        },
    },
    mounted() {
        this.infoId = this.$route.query.infoId
        this.token = this.$route.query.token
        if(this.$route.query.share == 1) {
            this.share = 1
        }
        this.load();
    }
}
</script>

<style scoped lang="scss">

#viewer {
    font-family: 微软雅黑;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
}

#mainTitle{
    img{
        position: absolute;
        left: 24px;
        top:54px;
        z-index: 10;
    }
    span{
        position: absolute;
        left: 50px;
        top:52px;
        z-index: 10;
    }
}

.backButton{
    z-index: 10;
    height:48px;
    width: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 12px;
    position: absolute;
    left: 10px;
    top:40px;
}

.compareSelect{
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
    z-index: 9;
    #top{
        width: 100%;
        height: 100px;
        background: rgba(246,246,246,1);
        font-size: 16px;
        font-weight: 500;
        color: black;
        text-align: left;
        position: relative;
        margin-bottom: 12px;
    }
    .item{
        height: 124px;
        position: relative;
        .selector {
            position: absolute;
            top:52px;
            left: 28px;
            width: 16px;
            height: 16px;
            background: #ffffff;
            border: 2px solid #4a4a4a;
            border-radius: 50%;
            .selectedPoint {
                width: 12px;
                height: 12px;
                background: #6a6ec3;
                border-radius: 50%;
                margin: 2px;
            }
        }
        .bottomLine {
            position: absolute;
            bottom: 0;
            left: 5px;
            right: 5px;
            height: 4px;
            background: #f2f2f2;
        }
        .thumb{
            position: absolute;
            top:12px;
            left: 57px;
            width: 96px;
            height: 96px;
            border: 1px solid #e5e5e5;
            border-radius: 8px;
            .thumbPicture{
                margin: 5px;
                width: 86px;
                height: 86px;
                border-radius: 4px;
                object-fit:cover;
            }
            .thumbLock{
                position: absolute;
                top:5px;
                left: 5px;
                width: 86px;
                height: 86px;
                border-radius: 4px;
                background: rgba(0,0,0,0.6);
            }
        }
        .title{
            position: absolute;
            left: 170px;
            top: 17px;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: #4a4a4a;
            line-height: 20px;
        }
    }
    #bottom{
        margin: 4px 28px;
        text-align: left;
        color: rgba(204,204,204,1);
        font-size: 12px;
        line-height: 18px;
    }
    #button{
        text-align: center;
        width: 83px;
        height: 32px;
        background: #e5e5e5;
        border-radius: 18px;
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        line-height: 32px;
        margin: 32px auto 20px;
    }
    #info{
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        width: 100%;
        color: rgba(0,0,0,0.6);
    }
}


.compareEntry{
    position: absolute;
    left: 0;
    bottom: 144px;
    color: white;
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    background: rgba(0,0,0,0.6);
    width: 67px;
    height: 30px;
    border-radius: 0px 16px 16px 0px;
}

.info{
    position: absolute;
    right: 0;
    bottom: 144px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    text-align: left;
    padding: 0 16px;
    pointer-events: none;
}

.locked {
    padding-top: 40vh;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    color: white;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.timeline{
    width: 100%;
    height: 55px;
    position: absolute;
    left: 0;
    bottom: 48px;
    display: flex;
    justify-content: space-around;
    .line{
        position: absolute;
        top:2.5px;
        height: 3px;
        left: 0;
        right: 0;
        .subline{
            position: absolute;
            top:0;
            left: 0;
            height: 3px;
            background: white;
        }
    }
    .parts{
        min-width: 64px;
        height: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: white;
        line-height: 20px;
        z-index: 1;
        .point{
            margin: 0 auto 5px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background: linear-gradient(180deg,#cecccc, #ffffff);
        }
    }
}


</style>
